import { getActivePinia } from 'pinia'

import { useDraftPromoStore } from '~/stores/draftPromo'
import { useMiscDraftProgressivePromosStore } from '~/stores/miscDraftProgressivePromos'

export default defineNuxtRouteMiddleware(async () => {
  const pinia = getActivePinia()

  await useMiscDraftProgressivePromosStore(pinia).FETCH()
  await useDraftPromoStore(pinia).CHECK_FOR_PROGRESSIVE_PROMO()
})
